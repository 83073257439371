import { DestroyRef, Injectable, effect, inject, signal, untracked } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { OrganizationReadOnlyControllerService } from '@garmin-avcloud/avcloud-fly-web-common/api/orgs-roles/read';
import { OrganizationWriteControllerService } from '@garmin-avcloud/avcloud-fly-web-common/api/orgs-roles/write';
import {
  AbstractOrgSettingsService,
  SelectedOrgService,
  StatusBanner
} from '@garmin-avcloud/avcloud-fly-web-common/orgs';
import { EMPTY, Observable, switchMap, tap, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrgSettingsService extends AbstractOrgSettingsService {
  private readonly destroyRef = inject(DestroyRef);
  private readonly selectedOrgService = inject(SelectedOrgService);
  private readonly organizationReadOnlyControllerService = inject(OrganizationReadOnlyControllerService);
  private readonly organizationWriteControllerService = inject(OrganizationWriteControllerService);
  readonly status = signal<StatusBanner | null>(null);
  readyToUpdate = false;

  constructor() {
    super();
    this.loadSettings()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        error: () => console.error('Unable to load org settings')
      });

    effect(() => {
      const uuid = untracked(this.selectedOrgService.org)?.uuid;
      if (uuid == null) return;
      const allowedDomains = this.allowedEmailDomains();
      if (this.readyToUpdate) {
        this.organizationWriteControllerService
          .updateOrganizationAllowedEmailDomains(uuid, allowedDomains)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe({
            error: () => this.status.set({ type: 'error', message: 'unable to save allowed email domains' })
          });
      }
      this.readyToUpdate = true;
    });

    this.useCustomDomainList.valueChanges.pipe(takeUntilDestroyed()).subscribe((useCustom) => {
      if (!useCustom) {
        this.allowedEmailDomains.set([]);
      }
    });
  }

  override loadSettings(): Observable<never> {
    // NOTE: we're not resetting here because we don't want to trigger a save
    const uuid = this.selectedOrgService.org()?.uuid;
    if (uuid == null) {
      this.status.set({ type: 'error', message: 'no org selected' });
      return throwError(() => new Error('No org selected'));
    }
    return this.organizationReadOnlyControllerService.getOrganizationAllowedEmailDomains(uuid).pipe(
      tap({ error: () => this.status.set({ type: 'error', message: 'unable to load settings' }) }),
      switchMap((domains) => {
        this.allowedEmailDomains.set(domains);
        this.useCustomDomainList.setValue(this.allowedEmailDomains().length > 0);
        return EMPTY;
      })
    );
  }

  domainsEquality(originalDomains: string[], newDomains: string[]): boolean {
    if (originalDomains.length === newDomains.length) {
      return true;
    }
    return false;
  }
}
